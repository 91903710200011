import React from 'react';
import {
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from '@mui/icons-material';

const defaultIconValues = {
  size: '25px',
  fbcolor: '#3b5998',
  linkedinColor: '#0072b1',
  youtubeColor: '#c4302b',
  instaColor: '#cd486b',
};

const socialMediaIcons = [
  <FacebookIcon
    key={0}
    style={{ fontSize: defaultIconValues.size, color: defaultIconValues.fbcolor }}
  />,
  <LinkedInIcon
    key={1}
    style={{ fontSize: defaultIconValues.size, color: defaultIconValues.linkedinColor }}
  />,
  <InstagramIcon
    key={2}
    style={{ fontSize: defaultIconValues.size, color: defaultIconValues.instaColor }}
  />,
  <YouTubeIcon
    key={3}
    style={{ fontSize: defaultIconValues.size, color: defaultIconValues.youtubeColor }}
  />,
];

export const socialMedia = [
  {
    id: 0,
    href: 'https://www.facebook.com/profile.php?id=61559410351550',
    icon: socialMediaIcons[0],
  },
  {
    id: 1,
    href: 'https://www.linkedin.com/company/102890417/admin/feed/posts/',
    icon: socialMediaIcons[1],
  },
  {
    id: 2,
    href: 'https://www.youtube.com/@OldPortsmouthCollege',
    icon: socialMediaIcons[3],
  },
  {
    id: 3,
    href: 'https://www.instagram.com/oldportsmouthcollege/?next=%2F',
    icon: socialMediaIcons[2],
  },
];

export const authStyles = {
  root: {
    width: '100%',
    display: 'flex',
    height: 'screen',
    color: 'grey.800',
    fontWeight: '700',
    alignItems: 'center',
    fontSize: '0.875rem',
    alignContent: 'center',
    justifyContent: 'space-around',
    // borderRadius: '8px',
  },
  root2: {
    padding: 1,
    display: 'flex',
    maxWidth: '650px',
    flexDirection: 'row',
    borderRadius: '8px',
    background: 'rgba(255, 255, 255, 0.8)',
  },
  firstHalfRoot: {
    width: '50%',
    bgcolor: '#D6E4E5',
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  maxWidth: {
    maxWidth: '13rem',
    paddingBottom: '80px',
  },
  secondHalfRoot: {
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'flex-end',
    justifyContent: 'space-around',
  },
  formRoot: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
  },
  socialMedia: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  btn: {
    partnerButton: {
      transition: '0.3s',
      '&:hover': {
        color: '#fff',
        borderColor: '#44c7f4',
        backgroundColor: '#0066b2',
      },
    },
    studentButton: {
      transition: '0.3s',
      '&:hover': {
        color: '#fff',
        borderColor: '#E80F88',
        backgroundColor: '#AF0171',
      },
    },
    refButton: {
      transition: '0.3s',
      '&:hover': {
        color: '#fff',
        borderColor: '#0097A7',
        backgroundColor: '#0097A7',
      },
    },
    loginButton: {
      padding: 8,
      color: '#fff',
      width: '100%',
      border: 'none',
      cursor: 'pointer',
      transition: '0.2s',
      fontSize: '1.2rem',
      fontWeight: '1.2rem',
      backgroundColor: '#11aae2',
      borderRadius: '4px 4px 4px 4px',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#05bcff',
      },
    },
  },
};

const AuthComponent = () => (
  <div style={authStyles.root}>
    <div style={authStyles.root2}>
      <div style={authStyles.firstHalfRoot}>
        {/* Add your first half content here */}
      </div>
      <div style={authStyles.secondHalfRoot}>
        <div style={authStyles.formRoot}>
          {/* Add your form content here */}
        </div>
        <div style={authStyles.socialMedia}>
          {socialMedia.map((item) => (
            <a key={item.id} href={item.href} target="_blank" rel="noopener noreferrer">
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default AuthComponent;
